
export default function Footer() {
  return (
    <div className="Footer">
      <footer className="footer-section">
    <div className="section-padding footer">
      <div className="container-default w-container">
        <div className="footer-wrp">
          <div className="small-text letter-spacing-small">© 2024 Shopik <a href="http://unroot.design" target="_blank" className="footer-link"></a>
          </div>
          <div className="small-text letter-spacing-small">Powered By Shopik</div>
          <div className="footer-links-wrp">
            <a href="/licenses" className="white-footer-link">Licenses</a>
          </div>
        </div>
      </div>
    </div>
  </footer>
    </div>
  );
}
