import React from 'react';
import { get, post } from './api';
import { useAuth } from './authContext';
import { formattedDate } from './sharedUtils';
import { Container, Typography, Select, MenuItem, Grid, Paper, Button, CircularProgress } from '@mui/material';

// Example price IDs (replace with your actual Stripe price IDs)

const priceOptions =
  process.env.REACT_APP_PROJECT_ID === 'shopik-production'
    ? [
        { id: 'price_1Q5tj4B4N8co00kD0Lau6mW0', label: 'Basic Plan - $10/month' },
        { id: 'price_1Q5tjTB4N8co00kDCQty6vBH', label: 'Pro Plan - $25/month' },
        { id: 'price_1Q5tjWB4N8co00kDd5QkKvLH', label: 'Power Seller Plan - $100/month' },
      ]
    : [
        // Test environment or staging prices
        { id: 'price_1Q5tbwB4N8co00kDLZni6vC8', label: 'test Basic Plan - $10/month' },
        { id: 'price_1Q5tglB4N8co00kDt6Orkfo1', label: 'test Pro Plan - $25/month' },
        { id: 'price_1Q5thXB4N8co00kDnj1d6vKU', label: 'test Power Seller Plan - $100/month' },
      ];

const StripePricingTable = () => {
  const { customer, user, logout } = useAuth();
  // Function to create Checkout session and redirect to Stripe
  const createCheckoutSession = async (priceId) => {
    try {
      const session = await post('/create-stripe-checkout-session', '',  JSON.stringify({
        priceId: priceId,
        customerId: customer.stripeCustomerId, // Pass the customer ID from your app
      })); 
     
      if (session.url) {
        window.location.href = session.url; // Redirect to Stripe Checkout
      } else {
        console.error('Error: Session URL not found');
      }
    } catch (error) {
      console.error('Error creating Checkout session:', error);
    }
  };
const basicFeatures = [
  'iOS app',
  'eBay integration',
  'Shopify integration'
]
const pricingPlans = [
  {
    title: 'Basic',
    price: '$10',
    period: '/ month',
    credits: '100 credits',
    products: '(up to 20 listings)',
    features: basicFeatures,
    buttonText: 'Subscribe',
    buttonAction: () => createCheckoutSession(priceOptions[0].id),
  },
  {
    title: 'Pro',
    price: '$25',
    period: '/ month',
    credits: '300 credits',
    products: '(up to 60 listings)',
    features:  basicFeatures,
    buttonText: 'Subscribe',
    buttonAction: () => createCheckoutSession(priceOptions[1].id),
  },
  {
    title: 'Power Seller',
    price: '$100',
    period: '/ month',
    credits: '1500 credits',
    products: '(up to 300 listings)',
    features:  basicFeatures,
    buttonText: 'Subscribe',
    buttonAction: () => createCheckoutSession(priceOptions[2].id),
    highlight: true, // Highlight this plan
  },
  {
    title: 'Enterprise',
    price: '-' ,
    products: 'Let’s talk',
    credits: '-',
    features: ['All Power Seller features', 'Custom integrations','API access', 'Bulk uploader access'],
    buttonText: 'Contact us',
    buttonAction: () => {
      const email = 'support@shopik.io';
      const subject = encodeURIComponent('Shopik Enterprise Plan Inquiry');
      const body = encodeURIComponent('Hi, I would like to inquire about the Enterprise Plan.');
      window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
    },
  },
];

  return (
    <>
    {customer.basePlan && customer.basePlan.periodEndDate ? (<>
      {/* <Typography variant="h6" style={{ marginTop: 20 }}>
      You have a plan with {customer.basePlan} credits per month that renews on {formattedDate(customer.basePlan.periodEndDate)}
    </Typography> */}
    <Typography variant="h6" style={{ marginTop: 20 }}>
      You have a plan. Contact support to change or cancel your plan.
    </Typography>
    </>) : (<>
      <div className="pricing-table-container">
      {pricingPlans.map((plan, index) => (
        <div key={index} className={`pricing-card ${plan.highlight ? 'highlight' : ''}`}>
          <h3>{plan.title}</h3>
          <p className="credits">{plan.credits}</p>
          <p className="products">{plan.products}</p>
          <p className="price">
            <span>{plan.price}</span> <span>{plan.period}</span>
          </p>
          <button onClick={plan.buttonAction}>{plan.buttonText}</button>
          <ul className="features">
            {plan.features.map((feature, i) => (
              <li key={i}>{feature}</li>
            ))}
          </ul>
        </div>
        
      ))}
    </div>  
    </>
    )}
    <ul className='credit-explanation'>
        <li>A product listing uses 5 credits. </li>
        <li>Background removal uses 1 credit per photo.</li>
        <li>Credits expire at the end of each month.</li>
    </ul>
    </>
  );
};

export default StripePricingTable;