import React, { useState } from 'react';
import { db } from '../firebase';
import { doc, getDoc, setDoc, collection } from 'firebase/firestore';

const ProfileForm = ({shopikUser, updateData, uploaderStyle}) => {
  const defaultInsta = shopikUser.instagram ? shopikUser.instagram.replace('@','') : ''
  const [name, setName] = useState(shopikUser.name);
  const [email, setEmail] = useState(shopikUser.email);
  const [instagramHandle, setInstagramHandle] = useState(defaultInsta);
  const [phoneNumber, setPhoneNumber] = useState(shopikUser.phoneNumber);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (name) {
      shopikUser.name = name;
    }else {
      shopikUser.name = ''
    }
    if (instagramHandle) {
      shopikUser.instagram = '@' + instagramHandle.replace('@','');
    } else {
      shopikUser.instagram = ''
    }
    if (phoneNumber) {
      shopikUser.phoneNumber = phoneNumber;
    } else {
      shopikUser.phoneNumber = ''
    }
    if (email) {
      shopikUser.email = email;
    } else {
      shopikUser.email = ''
    }
    const dbUserRef = collection(db, "users")
    const userRef = doc(dbUserRef, shopikUser.userId) 
    await setDoc(userRef, {'name': shopikUser.name, 'instagram': shopikUser.instagram, 'phoneNumber': shopikUser.phoneNumber, 'email': shopikUser.email}, { merge: true });
    updateData({ name: shopikUser.name, instagram: shopikUser.instagram, phoneNumber: shopikUser.phoneNumber });
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '300px' }}>
        <label>
          <input
            type="text"
            value={name}
            placeholder='First and Last Name'
            className='custom-text-input'
            onChange={(e) => setName(e.target.value)}
            style={{ margin: '10px 0', padding: '8px' }}
            required
          />
        </label>
        { uploaderStyle === 1 && (
        <label>
          <input
            type="text"
            placeholder='Instagram Handle'
            className='custom-text-input'
            value={instagramHandle}
            onChange={(e) => setInstagramHandle(e.target.value)}
            style={{ margin: '10px 0', padding: '8px' }}
            required
          />
        </label>
        )}
        <label>
          <input
            type="text"
            placeholder='Email'
            className='custom-text-input'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ margin: '10px 0', padding: '8px' }}
            required
          />
        </label>
        <label>
          <input
            type="text"
            value={phoneNumber}
            className='custom-text-input'
            placeholder='Phone Number'
            onChange={(e) => setPhoneNumber(e.target.value)}
            style={{ margin: '10px 0', padding: '8px' }}
            required
          />
        </label>
        <button className="custom-button" type="submit">
          Save
        </button>
      </form>
    </div>
  );
};

export default ProfileForm;
