import React, { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';

export default function DashboardLayout({ children }) {
  const navigate = useNavigate();
  const user = auth.currentUser;
  
  // State to toggle the menu, default based on screen size
  const [menuOpen, setMenuOpen] = useState(window.innerWidth >= 768);

  useEffect(() => {
    // Function to update menu state based on window resize
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setMenuOpen(true);  // Expanded by default on desktop
      } else {
        setMenuOpen(false); // Collapsed by default on mobile
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLogout = () => {
    auth.signOut()
      .then(() => {
        navigate('/');
      })
      .catch((error) => {
        console.error("Error logging out: ", error);
      });
  };

  // Toggle the menu open/close on mobile
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className={`dashboard-layout ${menuOpen ? 'menu-open' : ''}`}>
      <div className="dashboard-container">
        {/* Top section for user info */}
        <div className="top-nav">

          {/* Hamburger Menu Icon for Mobile */}
          <button className="hamburger-menu" onClick={toggleMenu}>
            <img src='./images/menu.png'height={35}/>
          </button>
            <div className="user-info">
            <span>{user ? user.email : "Guest"}</span>
            <button className="logout-button" onClick={handleLogout}>Sign Out</button>
          </div>

        </div>

        {/* Left-side navigation */}
        <aside className={`side-nav ${menuOpen ? 'open' : ''}`}>
          <nav className="nav-menu">
            <ul>
              <li><a href="/dashboard">Usage</a></li>
              <li><a href="/plans">Plan</a></li>
              <li><a href="/settings">Settings</a></li>
              <li><a href="mailto:hello@shopik.io">Support</a></li>
            </ul>
          </nav>{/* User info section moved to the bottom */}
        </aside>

        <div className="main-content">
          {children}
        </div>
      </div>
    </div>
  );
}