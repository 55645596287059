import HLSVideoPlayer from './videoPlayer.js'
import AppStoreButton from './appStoreButton.js';

export default function HomeContent() {
  return (
    <div className="HomeContent">
      <div className="hero-section"><img src="images/Hero-Img.png" loading="lazy" width="1314" sizes="(max-width: 991px) 100vw, 1313.9931640625px" alt="" srcSet="images/Hero-Img2-p-500.png 500w, images/Hero-Img2-p-800.png 800w, images/Hero-Img2-p-1080.png 1080w, images/Hero-Img2-p-1600.png 1600w, images/Hero-Img2-p-2000.png 2000w, images/Hero-Img.png 2628w" className="hero-bg-image"/>
        <div className="section-padding hero">
          <div data-w-id="90832f18-3ab9-8b37-6efb-6fe495a4707c" style={{WebkitTransform: 'translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1}} className="container-default">
            <div className="hero-container">
              <div className="hero-ttitle-wrp">
                <h1>Create Product Descriptions From Images Using AI</h1>
              </div>
              <div className="hero-text-wrp">
                <p>And so much more.<br/></p>
              </div>
              <div className="hero-buttons-wrp">
                <a href="/tryitnow" className="main-button w-button">Try It Out</a>
                <div style={{paddingTop: '4px'}}><AppStoreButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="video-section">
        <div className="section-padding video-padding">
          <div className="container-default">
            <div data-w-id="b043a860-5765-7f86-92c8-be4202ef1cdf" style={{WebkitTransform: 'translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1}} className="video-container">
            <div className="video-wrp">
                <HLSVideoPlayer/>
                {/* <div style={{paddingTop: '56.17021276595745%'}} className="video w-video w-embed"><iframe className="embedly-embed" src="https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FkjxPHfQV7UY%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DkjxPHfQV7UY&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FkjxPHfQV7UY%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube" scrolling="no" allowFullScreen title="Empty Your Garage In 5 Minutes! The World's Fasting Listing Tool!" /></div> */}
              </div>
              <div className="heading-texts-wrp">
              
                <div className="heading-wrp">
                  <h2><span className="gradient-text">Simplify your listing process using<br/></span> AI & Computer Vision</h2>
                </div>
                <div className="video-text-wrp">
                  <p>Shopik is AI&nbsp;created for resellers. Our innovation was based on your dilemma - you sell each item once, and never stock that item again. The keyboard is holding you back, making it costly and tedious to list each item. With Shopik, there is no keyboard, only a camera. As fast as you can take photos,&nbsp;Shopik creates full product listings.</p>
                </div>
                <br/><br/>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div id="features" className="ai-section">
          <div className="section-padding">
            <div data-w-id="c7bb8182-5da8-117c-c76e-ef182c49464e" style={{WebkitTransform: 'translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1}} className="container-default w-container">
              <div className="ai-block-wrp">
                <div className="ai-header-block">
                  <div className="ai-header-wrp">
                    <div className="tag-block-wrp">
                      <div className="tag-text-block">
                        <div>AI for Everyone</div>
                      </div>
                    </div>
                    <div className="ai-heading-texts-wrp">
                      <div className="heading-wrp">
                        <h2 className="white-text">Computer Vision, Machine Learning, and<br />Generative AI<br />‍<span className="gradient-text">Come Together At Shopik</span></h2>
                      </div>
                      <div className="ai-text-wrp">
                        <p>Shopik harnesses the power of AI to help businesses automate the repetitive tasks of writing descriptions, writing titles, and guessing prices. Shopik instantly finds any unique identifiers within the photos to determine the product. Let our image analysis take away these routine tasks to unlock insights and improve efficiency of your business. Our intuitive tools and advanced analytics make it easy to get up and running quickly.</p>
                      </div>
                    </div>
                    <div className="ai-buttons-wrp">
                      <a href="mailto:hello@shopik.io" className="main-button w-button">Get started</a>
                      <a href="#signup" className="main-button second w-button">Learn More</a>
                    </div>
                  </div>
                </div>
                <div className="ai-cards-block">
                  <div className="ai-card-item">
                    <div className="ai-heading-3-wrp">
                      <h3 className="gradient-text">Background Removal</h3>
                    </div>
                    <p>We instantly remove the background from your products. Our AI&nbsp;platform uses a combination of object detection, boundary detection, and proprietary techniques to identify exactly what you're selling and remove everything else from the frame. You can access background removal on the web, in mobile, directly via API&nbsp;or as part of the full product listing creation.</p>
                  </div>
                  <div className="ai-card-item">
                    <div className="ai-heading-3-wrp">
                      <h3 className="gradient-text">Full Product Specifications</h3>
                    </div>
                    <p>We create a title, description, and complete specifications for your product. You can customize what data that you would like to fill out for your products, or just use our AI to determine what would make sense based on each product.</p>
                  </div>
                  <div className="ai-card-item">
                    <div className="ai-heading-3-wrp">
                      <h3 className="gradient-text">Price Analysis</h3>
                    </div>
                    <p>Shopik finds the possible sales value for your product and creates a pricing matrix. Your product is priced and listed instantly online, but you can also choose to price it at a strategic value based on the meaningful data that we provide.</p>
                  </div>
                  <div className="ai-card-item">
                    <div className="ai-heading-3-wrp">
                      <h3 className="gradient-text">Post Anywhere</h3>
                    </div>
                    <p>Shopik can post your item online to a growing set of sales channels, including eBay and Shopify. We also have a marketplace that you can use to host the listing and provide a link that you can post on every marketing platform you desire.</p>
                  </div>
                  <div className="ai-card-item">
                    <div className="ai-heading-3-wrp">
                      <h3 className="gradient-text">Customize Your Workflow</h3>
                    </div>
                    <p>Shopik can work with you to take your spreadsheet data or folder full of images to automate all of your tedious data entry and reduce labor costs. We are passionate about automation and saving you time that you and your team could spend on more important tasks.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="solution" className="cards-section">
          <div className="section-padding">
            <div className="container-default w-container">
              <div data-w-id="fa0abc52-709a-fd05-0cd9-8d338e3a258f" style={{WebkitTransform: 'translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1}} className="cards-wrp">
                <div className="heading-texts-wrp">
                  <div className="heading-wrp">
                    <h2><span className="gradient-text">Mobile App, Web, API,  & </span><br/>Custom Solutions</h2>
                  </div>
                  <div className="cards-text-wrp">
                    <p>Try it out and experience first-hand the potential of AI.</p>
                  </div>
                </div>
                <div className="cards-block-wrp">
                  <div className="w-layout-grid cards-block">
                    <div className="card-container">
                      <div className="card-start-container">
                      <img src="images/pink-star.svg" loading="lazy" alt="" />
                      </div>
                      <div className="card-heading-wrp">
                        <h3>Shopik iOS&nbsp;App</h3>
                      </div>
                      <p>Point the phone at any object, and with a photo, the item can online and for sale in no time. No keyboard, no waiting. Download the mobile app today on the app store. All you need is a camera, or our app to take a photo and we do the rest. </p>
                    </div>
                    <div className="card-container">
                      <div className="card-start-container"><img src="images/violet-star.svg" loading="lazy" alt="" /></div>
                      <div className="card-heading-wrp">
                        <h3>REST API</h3>
                      </div>
                      <p>Shopik offers an API for you to catalog your products automatically. Sign up for an API key to try it out!</p>
                    </div>
                    <div className="card-container">
                      <div className="card-start-container"><img src="images/blue-star.svg" loading="lazy" alt="" /></div>
                      <div className="card-heading-wrp">
                        <h3>Enterprise Solutions</h3>
                      </div>
                      <p>Shopik is designed to handle the needs of customers of any size.&nbsp;We can help your business integrate with Shopik however it fits in to your current process. 
                        Do you want to throw your images in a folder and let us take it from there? How about take a spreadsheet and convert that to online products with complete specifications?</p>
                    </div>
                  </div>
                </div>
                <div className="card-buttons-wrp">
                  <a href="/login" className="main-button w-button">Get started</a>
                  <a href="mailto:hello@shopik.io" className="main-button second w-button">Learn More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="map-section" hidden>
          <div className="section-padding map">
            <div className="container-default w-container">
              <div data-w-id="59122919-f2c6-1b60-273c-a1ddbf3c13e0" style={{WebkitTransform: 'translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1}} className="map-main-container">
                <div className="map-wrp"><img src="images/map.png" srcSet="images/map-p-500.png 500w, images/map.png 2200w" width={1100} sizes="92vw" alt="" /></div>
                <div className="map-content-wrp">
                  <div className="white-tag-text-block">
                    <div>Explore</div>
                  </div>
                  <div className="map-heading-padding">
                    <div className="heading-texts-wrp">
                      <div className="heading-wrp">
                        <h2>Unlock Your Offline Inventory with <span className="gradient-text">AI&nbsp;&nbsp;</span></h2>
                      </div>
                      <div className="map-text-wrp">
                        <p>Shopik allows you to list thousands of items faster than you ever thought possible.&nbsp;As fast as you can click a photo we can create a description and listing online. Using groundbreaking Computer Vision, Machine Learning, and Generative AI&nbsp;solutions Shopik has created the ultimate platform for sellers to get online. </p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="map-buttons-wrp">
                    <a href="mailto:hello@shopik.io" className="main-button w-button">Discover</a>
                    <a href="#pricing" className="main-button second w-button">Pricing</a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>       
        <div id="signup" className="cta-section">
          <div className="section-padding cta">
            <div className="container-default w-container">
              <div id="w-node-d7266b0f-c5a5-50ae-bc3b-46170a2d004d-75d93b52" data-w-id="d7266b0f-c5a5-50ae-bc3b-46170a2d004d" style={{WebkitTransform: 'translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1}} className="cta-wrp">      
                <div id="w-node-d7266b0f-c5a5-50ae-bc3b-46170a2d004f-75d93b52" className="cta-heading-wrp">
                  <h2 className="white-text">Get Started <span className="gradient-text">with&nbsp;Shopik</span></h2>
                  <br/><br/>
                </div>
                <a href="/login" className="main-button w-button">Sign Up Now</a>              
              </div>
            </div>
          </div>
        </div>
        <div className="help-box">
        <a href="/tryitnow" className="main-button help">Try It Out</a>
        </div>
    </div> 
  );
}
