import { db } from '../firebase.js'
import { doc, collection, getDoc } from "firebase/firestore";
import { useParams } from 'react-router';
import $ from 'jquery'; 
import { useEffect, useState} from 'react';
import { Component } from 'react';
import React from 'react';

import { useNavigate } from 'react-router-dom'; 

export default function Product() {
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    window.location.replace('https://' + process.env.REACT_APP_MARKETPLACE_URL + '/products/' + params['customer'] + '/' + params['product']);
  }, [navigate]);

  // Return null to prevent rendering anything for this component
  return null;
}

