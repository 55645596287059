import React, { useState } from 'react';
import { TextField, Button, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { styled } from '@mui/system';

// Styled container to align button and input
const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
});

const ApiKeyField = ({ apiKey, regenerateApiKey }) => {
  const [showApiKey, setShowApiKey] = useState(false); // State to control visibility

  const handleToggleVisibility = () => {
    setShowApiKey(!showApiKey); // Toggle the visibility of the field
  };

  return (
    <Container>
      <TextField
        label="API Key"
        type={showApiKey ? 'text' : 'password'} // Toggle between text and password
        value={apiKey} // Use the passed apiKey prop
        fullWidth
        InputProps={{
          readOnly: true, // Make the field read-only
          endAdornment: (
            <InputAdornment position="end">
              <Button onClick={handleToggleVisibility}>
                {showApiKey ? <VisibilityOff /> : <Visibility />}
              </Button>
            </InputAdornment>
          ),
        }}
      />
    </Container>
  );
};

export default ApiKeyField;