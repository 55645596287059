import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, Paper, Grid } from '@mui/material';
import { get, post } from './api';
import { useAuth } from './authContext';
import { useNavigate } from 'react-router-dom';
import ApiKeyField from './apiKeyField';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Import your Firebase configuration

const Settings = () => {
    const navigate = useNavigate();
    const { customer, user, logout, shopikUser } = useAuth(); // Assuming 'customer' contains user data
    const [apiKey, setApiKey] = useState(''); // API key state
    const [dataChanged, setDataChanged] = useState(false);
    const [userData, setUserData] = useState({
        // email: '',
        name: ''
    });

    useEffect(() => {
        // Fetch user data and API key when component mounts
        if (customer) {
            setUserData({
                // email: user.email || '',
                name: shopikUser.name || ''
            });
            setApiKey(customer.apiKey)
        }

    }, [customer]);

    // Update user data
    const handleUserDataChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevData) => ({
            ...prevData,
            [name]: value
        }));
        setDataChanged(true)
    };

    // Save user data to Firebase
    const saveUserData = async () => {
        try {
            console.log(customer.id + ":" + user.id + ":" + shopikUser)
            if (customer && customer.id) { // Ensure customer has an id
                const userDoc = doc(db, 'users', customer.id); // Reference to the user's document in Firestore
                await setDoc(userDoc, userData, { merge: true }); // Update the document, merging fields if it exists
            } else {
                console.error('Customer ID not found. Cannot save user data.');
            }
        } catch (error) {
            console.error('Error updating user data:', error);
        }
    };

    // Regenerate API Key
    const regenerateApiKey = async () => {
        try {
            const response = await post('/regenerate-api-key'); // Replace with actual API endpoint
            setApiKey(response.apiKey);
            alert('API key regenerated successfully!');
        } catch (error) {
            console.error('Error regenerating API key:', error);
        }
    };

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    return (
        <Container>
            <Paper style={{ padding: 20, marginBottom: 20 }}>
                <Typography variant="h6" gutterBottom>
                    Personal Information
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Name"
                            variant="outlined"
                            fullWidth
                            name="name"
                            value={userData.name}
                            onChange={handleUserDataChange}
                        />
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                        <TextField
                            label="Email"
                            variant="outlined"
                            fullWidth
                            name="email"
                            value={userData.email}
                            onChange={handleUserDataChange}
                        />
                    </Grid> */}
                </Grid>                
            </Paper>
            {(customer.apiKey && (
                <Paper style={{ padding: 20 }}>
                <Typography variant="h6" gutterBottom>
                    API Key
                </Typography>
                <ApiKeyField apiKey={apiKey} regenerateApiKey={regenerateApiKey} />
            </Paper>
            ))}
            { dataChanged && (
                <Button
                variant="contained"
                sx={{
                    backgroundColor: '#4751ed', // Custom background color
                    color: 'white', // Text color
                    textTransform: 'none',
                    ':hover': {
                      backgroundColor: '#6133aa', // Hover color
                    },
                }}
                style={{ marginTop: 20 }}
                onClick={saveUserData} // Save user data to Firebase
            >
                Save
            </Button>  
            )}
            
        </Container>
    );
};

export default Settings;